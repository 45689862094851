import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, title }) {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription = description || data.site.siteMetadata.description;
                const metaTitle = title || data.site.siteMetadata.title;
                return (
                    <Helmet
                        htmlAttributes={{
                            lang,
                        }}
                        title={metaTitle}
                        titleTemplate={`%s`}
                        meta={[
                            {
                                name: 'description',
                                content: metaDescription,
                            },
                            {
                                name: 'facebook-domain-verification',
                                content: 'bgmv6hbo0ekpxj62m6iwrdrrkrd1oa',
                            },
                            {
                                property: 'og:title',
                                content: title,
                            },
                            {
                                property: 'og:description',
                                content: metaDescription,
                            },
                            {
                                property: 'og:type',
                                content: 'website',
                            },
                            {
                                property: 'og:image',
                                content: '/images/open-graph/j-clarke-fitness.jpg',
                            },
                            {
                                name: 'twitter:card',
                                content: 'summary',
                            },
                            {
                                name: 'twitter:title',
                                content: title,
                            },
                            {
                                name: 'twitter:description',
                                content: metaDescription,
                            },
                            {
                                property: 'twitter:image',
                                content:
                                    'https://jclarkefitness.com/images/open-graph/j-clarke-fitness.jpg',
                            },
                            {
                                name: 'apple-mobile-web-app-title',
                                content: 'J Clarke Fitness',
                            },
                            {
                                name: 'application-name',
                                content: 'J Clarke Fitness',
                            },
                            {
                                name: 'msapplication-config',
                                content:
                                    'https://jclarkefitness.com/images/open-graph/j-clarke-fitness.jpg',
                            },
                            {
                                name: 'theme-color',
                                content: '#ffffff',
                            },
                        ].concat(meta)}
                        link={[
                            {
                                rel: 'apple-touch-icon',
                                sizes: '180x180',
                                href: '/images/favicons/apple-touch-icon.png',
                            },
                            {
                                rel: 'icon',
                                type: 'image/png',
                                sizes: '32x32',
                                href: '/images/favicons/favicon-32x32.png',
                            },
                            {
                                rel: 'icon',
                                type: 'image/png',
                                sizes: '16x16',
                                href: '/images/favicons/favicon-16x16.png',
                            },
                            {
                                rel: 'manifest',
                                href: '/images/favicons/manifest.json',
                            },
                            {
                                rel: 'mask-icon',
                                href: '/images/favicons/safari-pinned-tab.svg',
                                color: '#f44336',
                            },
                            {
                                rel: 'shortcut icon',
                                href: '/images/favicons/favicon.ico',
                            },
                        ]}
                        defer={false}
                    />
                );
            }}
        />
    );
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    title: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;
