import React from "react";

export default (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width || 26} height={props.height || 26} viewBox="0 0 26 26">
        <title>Follow me on Instagram</title>
        <g fill="#FFF">
            <path d="M18.958225 26H7.041775C3.159 26 0 22.841325 0 18.958225V7.041775C0 3.158675 3.159 0 7.041775 0h11.91645C22.841325 0 26 3.158675 26 7.041775V18.95855C26 22.841325 22.841325 26 18.958225 26zM7.041775 2.166775c-2.688075 0-4.875 2.186925-4.875 4.875V18.95855c0 2.688075 2.186925 4.875 4.875 4.875h11.91645c2.688075 0 4.875-2.186925 4.875-4.875V7.041775c0-2.688075-2.186925-4.875-4.875-4.875H7.041775z"/>
            <path d="M13 19.5c-3.5841 0-6.5-2.916225-6.5-6.5 0-3.584425 2.9159-6.5 6.5-6.5s6.5 2.915575 6.5 6.5c0 3.583775-2.9159 6.5-6.5 6.5zm0-10.833225c-2.3894 0-4.333225 1.943825-4.333225 4.333225 0 2.389725 1.943825 4.333225 4.333225 4.333225 2.3894 0 4.333225-1.9435 4.333225-4.333225 0-2.3894-1.9435-4.333225-4.333225-4.333225z"/>
            <circle cx="19.90625" cy="6.09375" r="1.489475"/>
        </g>
    </svg>
)