import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Logo from '../../images/logo';
import Youtube from '../../images/youtube';
import Instagram from '../../images/instagram';
import Facebook from '../../images/facebook';

import NewsletterForm from './newsletter-form';

export default props => (
    <footer className={`${props.borderTop ? ' borderTop' : ''}`}>
        <div>
            <div>
                <Link to="/">
                    <Logo color="white" />
                </Link>
                <p>&copy; {new Date().getFullYear()} J Clarke Fitness</p>
            </div>
            <div>
                <div>
                    <h3>Find out more</h3>
                    <nav>
                        <Link to="/">Coaching</Link>
                        <Link to="/nutrition-hub">Nutrition</Link>
                        <Link to="/about">About</Link>
                        <Link to="/testimonials">Testimonials</Link>
                    </nav>
                </div>
                <div>
                    <h3>Follow me</h3>
                    <nav>
                        <OutboundLink
                            href="https://www.youtube.com/channel/UC0TaX8wSzA7ogkVmmNEkURA"
                            target="blank"
                        >
                            <Youtube width="23.21" height="16.52" />
                            Youtube
                        </OutboundLink>
                        <OutboundLink
                            href="https://www.instagram.com/jclarke_fitness/"
                            target="blank"
                        >
                            <Instagram width="20" height="20" />
                            Instagram
                        </OutboundLink>
                        <OutboundLink
                            href="https://www.facebook.com/JClarkeFitness/"
                            target="blank"
                        >
                            <Facebook width="20" height="20" />
                            Facebook
                        </OutboundLink>
                    </nav>
                </div>
            </div>
            <div>
                <NewsletterForm label="Sign up for my newsletter" />
            </div>
        </div>

        <style jsx>{`
            footer {
                background-color: #263238;
                color: rgba(255, 255, 255, 0.7);

                & > div {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;
                    padding: 32px 24px;
                    margin: 0 auto;
                    max-width: 1240px;
                    box-sizing: border-box;

                    &.border-top {
                        border-top: 1px solid rgba(255, 255, 255, 0.3);
                    }

                    & > div {
                        margin: 32px auto;

                        &:first-of-type {
                            order: 3;

                            & :global(a) {
                                opacity: 1;
                            }

                            & > p {
                                font-size: 14px;
                                line-height: 24px;
                                opacity: 0.5;
                                text-transform: uppercase;
                            }
                        }

                        &:nth-child(2) {
                            order: 2;
                            & > div {
                                float: left;
                                margin: 0 30px;
                            }
                        }

                        &:last-of-type {
                            order: 1;
                            max-width: 400px;
                        }
                    }
                }

                & :global(h3),
                & :global(.newsletter-signup span),
                & p,
                & :global(a),
                & :global(p) {
                    color: #fff;
                }

                & :global(h3),
                & :global(.newsletter-signup span),
                & :global(a) {
                    font-size: 16px;
                    line-height: 24px;
                }

                & :global(h3),
                & :global(.newsletter-signup span) {
                    text-transform: initial;
                    padding: 8px 0 7px;
                    margin: 0;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                    display: inline-block;
                }

                & :global(a) {
                    text-decoration: none;
                    opacity: 0.7;
                    display: block;
                    margin: 8px 0;

                    &:hover {
                        opacity: 1;
                    }

                    & :global(svg) {
                        vertical-align: bottom;
                        margin: 0 10px 1px 1px;
                    }

                    &:first-of-type :global(svg) {
                        margin: 0 8px 1px 0;
                    }
                }
            }

            & :global(.newsletter-signup) {
                & :global(input) {
                    border-width: 0;
                }

                & :global(p) {
                    color: rgba(255, 255, 255, 0.7);

                    & > :global(a) {
                        display: inline;
                        color: rgba(255, 255, 255, 0.7);
                        opacity: 1;
                        font-size: 14px;
                        text-decoration: underline;

                        &::after {
                            display: none;
                        }

                        &:hover {
                            color: rgba(255, 255, 255, 1);
                        }
                    }
                }
            }

            @media (max-width: 400px) {
                footer {
                    & > div {
                        & > div:nth-child(2) {
                            text-align: center;

                            & > div {
                                margin: 24px 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            @media (min-width: 650px) {
                footer {
                    & > div {
                        flex-direction: row-reverse;

                        & > div:last-of-type {
                            width: 40%;
                        }
                    }
                }
            }

            @media (min-width: 975px) {
                footer {
                    & > div {
                        flex-direction: row;

                        & > div {
                            &:first-of-type {
                                order: 1;
                            }

                            &:nth-child(2) {
                                order: 2;
                            }

                            &:last-of-type {
                                width: 100%;
                                order: 3;
                            }
                        }
                    }
                }
            }
        `}</style>
    </footer>
);
