import React from 'react';

export default props => (
    <form
        action="https://jclarkefitness.us19.list-manage.com/subscribe/post?u=591ff13560951f3a5476438ad&amp;id=4149ad02f3"
        method="post"
        target="_blank"
        className="newsletter-signup"
    >
        <label>
            <span>{props.label}</span>
            <div>
                <input type="email" name="EMAIL" placeholder="Email" required />
                <input type="submit" value="Subscribe" />
            </div>
        </label>

        <p>
            By clicking subscribe, I have read and understood the{' '}
            <a href="/privacy-policy" target="blank">
                Privacy Policy
            </a>{' '}
            &amp;{' '}
            <a href="/terms-and-conditions" target="blank">
                Terms of Conditions
            </a>
            .
        </p>

        <style jsx>{`
            form {
                max-width: 480px;

                &:after {
                    content: '';
                    display: table;
                    clear: both;
                }
            }

            label {
                margin-top: 0;
            }

            input[type='email'] {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            input[type='submit'] {
                font-size: 12px;
                line-height: 16px;
                margin: 8px 0 0;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                box-shadow: none;
            }

            p,
            a {
                font-size: 14px;
                line-height: 20px;
            }

            div {
                display: flex;
                width: 100%;
            }

            @media (max-width: 500px) {
                input[type='email'],
                input[type='submit'] {
                    border-radius: 3px;
                }

                input[type='submit'] {
                    padding: 8px 16px;
                }

                div {
                    flex-wrap: wrap;
                    justify-content: flex-end;
                }
            }
        `}</style>
    </form>
);
