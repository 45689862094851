import React from 'react';
import PropTypes from 'prop-types';

import Header from './header';
import Footer from './footer';
import './layout.css';

const Layout = ({ children, noFooter, noHeader }) => (
    <>
        {!noHeader && <Header />}
        {children}
        {!noFooter && <Footer />}
    </>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    noFooter: PropTypes.bool,
    noHeader: PropTypes.bool,
};

export default Layout;
